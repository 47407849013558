.countdown-timer {
    background-color: var(--app-bg-color); /* Adjust according to your preferred colors */
    box-shadow: 1px 2px 0.84px rgba(0, 0, 0, 0.25);
    /* padding: 2px; */
    border: 1px solid var(--app-gray);
    border-radius: 8px;
    margin-bottom: 5px;
    width: 70px;
    /* padding-left: 0px; */
    padding-bottom: 3px;
    padding-top:3px ;
  }

  
  .timer-display {
    display: flex;
    justify-content: center;
    /* margin-bottom: 5px; */
  }
  
  .time-text {
    font-size: 12px;
    font-weight: bold;
    /* color: var(--app-red);  */
    color: var(--app-primary); 

    /* margin-left: 5px; */
    /* margin: 0 3px; */

  }
  
  .time-labels {
    display: flex;
    justify-content: center;
    font-size: 5px;
    color: var(--app-text-color); /* Adjust to match the app text color */
  }
  
  .label {
    margin: 0 3px;
  }

  @media (max-width: 480px) {
    .time-labels {
    font-size: 3px;
    }

    .time-text {
        font-size: 8px;
    }

    .countdown-timer {
        width: 50px;
    }

  }
  