/* src/CustomAlert.css */
.custom-alert {
    position: fixed;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--app-text-color);
    color: #721c24;
    padding: 25px;
    border: 1px solid #f5c6cb;
    border-radius: 5px;
    z-index: 1000;
  }
  
  .custom-alert-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  
  .custom-alert button {
    background: none;
    border: none;
    color: #721c24;
    font-weight: bold;
    cursor: pointer;
  }
  .custom-alert-close {
    color: red;
    border: 1px solid #ff0019;
    padding: 5px;
    margin: 10px;

  }
  .custom-alert-close:hover {
    cursor: pointer;
  }
  