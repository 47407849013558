
.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: var(--app-bg-color); */

  }

  /* Override the default styling of the popup */
.custom-popup .popup-content {
  width: 30% !important; /* Set the desired width */
  height: 80% !important; /* Set the desired height */
  /* background-color: #df0707; Set background color */
  border: none; /* Remove the default grey border */
  border-radius: 10px; /* Optional: add rounded corners */
  overflow: hidden; /* Ensure content does not overflow */
}

  .popup {
    /* Removed the unnecessary background styling */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--app-bg-color);
    /* width: 600px; */
    /* padding: 20px; Add padding to the popup */

  }
  .popup-inner {
    align-items: center;
    background-color: var(--app-bg-color);
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 15px;
    /* box-shadow: 0px 0px 10px 0px var(--app-bg-color); */
    width: 100%; 
    /* width: 500px;  */
    /* border: 1px solid #daec10; */
    /* height: 100%; */
    /* overflow: auto; */
    max-height: 80vh; 
    /* Ensure the popup doesn't exceed 80% of the viewport height */

    /* overflow-y: scroll; */

  }

  .popup-inner label {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 70%;
    /* align-items: center;
    align-self: center; */
    justify-content: center;
    /* align-content: center; */

  }
  
  .popup-inner input {
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    color: var(--app-text-color);
    background: none;
    /* border: none; */
    border: 1px solid var(--app-text-color);
    border-radius: 15px;

    font-size: 20px;
    cursor: pointer;
  }
  
  .profile-details {
    display: flex;
    flex-direction: column;
    /* align-items: center;
    align-content: center; */
  justify-content: center;
    padding: 10px;
  }

  .popup1 {
    position: fixed;
    /* z-index: 1; */
    left: 0;
    top: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: var(--app-bg-color);
    }
    
    .popup-inner1 {
    background-color: rgb(0, 0, 0);
    position: absolute;
    left: 50%;
    top: 60%;

    transform: translate(-50%, -50%);
    /* padding: 20px; */
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px var(--app-text-color);
    /* width: 40%; */
    border: 1px solid var(--app-text-color);
    /* margin-top: 20%; */
    margin-bottom: 20%;

    }

  
  /* Modal overlay styles */
.modal-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1;
  overflow: auto;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--app-bg-color); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  
}

/* Modal styles */
.modal {
  background-color: var(--app-bg-color);
  width: 30%; /* Adjust width as needed */
  /* height: 70%; Adjust height as needed */
  overflow-y: auto; /* Enable scrolling if content overflows */
  border-radius: 10px;
  padding: 20px;
  margin: 30px;
  /* position: relative; */
  border: 1px solid #f5c6cb;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

  .wrappereditProfile {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--app-bg-color);

  }
  .safe-area31 {
    width: 100%;
    max-width: 600px;
    /* height: 100%; */
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    opacity: 1;

  }

  .profile-card2 {
    flex: 1;
    /* background-color: #fff; */
    border-radius: 10px;
    /* box-shadow: 0 4px 8px var(--app-bg-color); */
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Space out children, pushing last one to bottom */
    margin-bottom: 20px; /* Optional: Add some margin at the bottom */
  }
  .container {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    overflow: hidden; /* This hides both the scrollbar and the scrollable content */

    /* For scrollable content with hidden scrollbar */
    overflow-y: scroll; /* Enables scrolling vertically without showing scrollbar */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer and Edge */
    background-color: var(--app-bg-secondary);

    /* Hide scrollbar for Chrome, Safari, and Opera */
}

  /* Hide scrollbar for webkit browsers (Chrome, Safari) */
.container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge */
.container22 {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  background-color: var(--app-bg-secondary);
}

  .container1 {
    padding: 20px;
  }
  
  .scrollView {
    overflow-y: scroll;
  }
  
  .textColor12 {
    color: var(--app-text-color);
    font-size: 12px;
  }
  .textColor10 {
    color: var(--app-text-color);
    font-size: 10px;
  }
  
  .textCenter {
    text-align: center;
  }
  
  .bold {
    font-weight: bold;
  }
  
  .italic {
    font-style: italic;
  }
  
  .textSecondaryBold12 {
    font-weight: bold;
    /* color: #666; */
    color: var(--app-primary);
    /* background-color: var(--app-bg-color); */
    text-align: center;

  }
  .textSecondaryBold10 {
    font-weight: bold;
    font-size: 10px;
    /* color: #666; */
    color: var(--app-primary);
    background-color: var(--app-bg-color);
    /* text-align: center; */
    text-align: start;

  }
  
  .marginTop10 {
    margin-top: 10px;
  }
  .marginTop20 {
    margin-top: 20px;
  }
  
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .col {
    flex: 1;
    padding: 10px;
  }

  .rowView {
    flex: 1;
    padding: 10px;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  
  .circularImageContainerMedium {
    width: 45px;
    height: 45px;
    overflow: hidden;
    border-radius: 50%;
  }

  .appLogoImageContainer {
    width: 85px;
    height: 85px;
    overflow: hidden;
    align-items: center;
    /* border-radius: 50%; */
  }

  .appNameImageContainer {
    width: 200px;
    height: 100px;
    overflow: hidden;
    align-items: center;
    /* background-color: #d01212; */
    /* border-radius: 50%; */
  }
  
  .circularImageMedium2 {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .column {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    gap: 2px; /* Adjust the spacing between elements */

  
  }

  
  
  .textBg10WidthStart {
    margin: 0; /* Remove default margin */
  /* padding: 5px 0; Adjust padding as needed */
    width: 100%;
    font-size: 14px;
    padding: 0px;
    text-align: start;
    /* color: white; */
    color:  var(--app-text-color);;
  }
  
  .textBg10Width {
    margin: 0; /* Remove default margin */
    padding: 0px;
  /* padding: 5px 0; Adjust padding as needed */
    width: 100%;
    font-size: 14px;
    text-align: center;
    color: var(--app-text-color);
  }
  .textBg12Width {
    margin: 0; /* Remove default margin */
    padding: 0px;
  /* padding: 5px 0; Adjust padding as needed */
    width: 100%;
    font-size: 12px;
    text-align: center;
    color: var(--app-text-color);
  }
  .textBg8Width {
    margin: 0; /* Remove default margin */
    padding: 5px 0; /* Adjust padding as needed */
    width: 100%;
    font-size: 12px;
    text-align: center;
    /* color: #444; */
    color: var(--app-text-color);
  }
  
  .black {
    color: black;
  }
  
  .green {
    color: green;
  }
  
  .red {
    color: red;
  }

  .secondary {

    color: #F67138;
  }

  .inputText1 {
    padding: 8px;
    margin: 5px 0;
    width: 80%;
    box-sizing: border-box;
    color: #d01212; /* Change text color */
    border: 2px solid #28a745; /* Outline color */
    outline: none; /* Remove default outline */
  }
  .inputText {
    /* padding: 8px; */
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin: 5px 0;
    width: 30%;
    box-sizing: border-box;
    color: var(--app-primary); /* Change text color */
border: 2px solid var(--app-green); /* Outline color */
outline: none; /* Remove default outline */
background-color: var(--app-bg-color);
border-radius: 20px;
margin-right: 10px;
  }
  .inputText:focus {
    border-color: var(--app-green); /* Change outline color on focus */
  }

  .inputTextEdit-profile {
    /* padding: 8px; */
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    /* margin: 3px 0; */
    /* width: 100vh; */
    box-sizing: border-box;
    font-size: 10px;
    color: var(--app-primary); /* Change text color */
border: 1px solid var(--app-gray); /* Outline color */
outline: none; /* Remove default outline */
background-color: var(--app-bg-color);
border-radius: 20px;
/* margin-right: 10px; */
  }
  .inputTextEdit-profile:focus {
    border-color: var(--app-green); /* Change outline color on focus */
  }

  

  .submitButton-edit-profile:hover {
    /* background-color: #218838; */
    border: 2px solid var(--app-green); /* Outline color */
    color: var(--app-green);

  }
  .submitButton-edit-profile {
    margin-top: 20px;
    width: 20vh;
    padding: 5px 10px;
    font-size: 10px;
    background-color: var(--app-bg-color);
    color: var(--app-primary);
    /* border: none; */
    border: 2px solid var(--app-gray); /* Outline color */
    cursor: pointer;
    border-radius: 20px;
    text-align: center;
  }

  
  @media (max-width: 768px) {
    .circularImageContainerMedium {
      width: 25px;
      height: 25px;
    }
    .textBg10Width {
      font-size: 12px;
    }
    textBg10WidthStart{
      font-size: 12px;
    }
    .textBg8Width {
      font-size: 8px;
      padding: 2px 0; /* Adjust padding as needed */

    }

    .container {
      max-width: 100%; /* Full width for mobile screens */
      padding: 10px;   /* Reduce padding for smaller screens */
    }
  
    .wrapper {
      padding: 10px;   /* Reduce padding for smaller screens */
    }

    .inputText {
      /* padding: 8px; */
      
      width: 60%;
     
    }
    .popup {
      /* Removed the unnecessary background styling */
      /* flex: 1; */
      /* height: 100%; */
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--app-bg-color);
      /* width: 600px; */
      /* padding: 20px; Add padding to the popup */
  
    }
    .popup-inner {
      align-items: center;
      background-color: var(--app-bg-color);
      /* position: relative; */
      padding-top: 10px;
      padding-bottom: 10px;
      border-radius: 5px;
      /* box-shadow: 0px 0px 10px 0px var(--app-text-color); */
      width: 100%; 
      /* width: 500px;  */
      /* border: 1px solid #f5c6cb; */
      /* height: 100%; */
      overflow: auto;
      max-height: 100vh; 
      /* Ensure the popup doesn't exceed 80% of the viewport height */
  
      /* overflow-y: scroll; */
  
    }

  }
  