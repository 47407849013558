/* LoadingScreen.css */
/* :root {
    --global-white-color: white;
    --app-primary:#F67138;
    --app-text-color: white;
    --app-bg-color: black;
  } */

  @keyframes colorChange {
    0% {
      color: var(--app-text-color);
    }
    30% {
      color: var(--app-primary);
    }
    90% {
      color: var(--app-text-color);
    }
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
.letter {
    display: inline-block;
    animation: fadeIn 0.5s ease-out forwards;
}
.loading-overlay-text{
  /* color: var(--app-primary); */
  color: var(--app-primary); /* Ensures the text is visible against the background */
  font-size: 15px;
  align-self: center;
  text-align: center;
  margin: 10px;
  font-style: italic;
  animation: colorChange 1.5s infinite; /* Color transition animation */
  opacity: 1;

}  
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: var(--app-bg-color); */
    background: rgba(0, 0, 0, 0.3); /* Use semi-transparent background color */

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    /* opacity: 0.3; */
    flex-direction: column;
  }
  
  .loading-spinner {
    border: 16px solid var(--app-text-color);
    border-top: 16px solid var(--app-primary);
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 2s linear infinite;
    opacity: 1;
  }
  
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  @media (max-width: 768px) {
    .loading-spinner {
      width: 40px;
      height: 40px;
    }
    .loading-overlay-text{
      font-size: 10px;
    }

  }
  