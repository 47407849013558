.market-list-screen {
  width: 100%;
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center;     /* Center content vertically if needed */
}

.crypto-list {
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column; /* Ensure the list stacks vertically */
  align-items: center;     /* Center the items */
  justify-content: center; /* Center the items */
}

.crypto-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem;
  padding: 1rem;
  width: 100%;
  max-width: 800px; /* Limit the width to center items */
  text-align: center;
}

.crypto-item-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10px;
}

.crypto-image {
  width: 25px;
  height: 25px;
  border: 2px solid var(--app-primary);
  border-radius: 15px;
}

.crypto-info {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  width: 100%;
  align-items: center;

  max-width: 800px;
}

/* Media queries for mobile view */
@media (max-width: 768px) {
  .crypto-list {
    width: 100%; /* Full width on mobile */
  }

  .crypto-item {
    width: 90%; /* Slightly reduced width on smaller screens */
    max-width: 400px; /* Adjust maximum width for mobile view */
  }

  .crypto-info p {
    font-size: 0.8rem; /* Slightly decrease font size for mobile view */
  }
}

@media (max-width: 480px) {
  .crypto-list {
    width: 100%; /* Full width on mobile */
  }
  .crypto-item {
    /* border:  2px solid wheat; */
    width: 80%;
    /* align-items: start; */
    /* align-self: start; */
    /* max-width: 100px; Further adjust maximum width for small mobile screens */
  }

  .crypto-info p {
    font-size: 0.6rem; /* Further decrease font size for smaller mobile screens */
  }
}
