/* html,
body,
.wrapperLogin {
    height: 100%;
    flex: 1;
    
    width: 100%;
     display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-self: center;
    background-color: black;

  } */

  /* html, body {
    height: 100%;
    margin: 0; 
    background-color: var(--app-bg-secondary);
    overflow: hidden;
  } */
  
  .wrapperLogin {
    height: 70vh;
    /* height: 100%; */
    flex: 1;
    /* overflow-y: hidden; */
    
    /* width: 100%; */
     display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-self: center;
    /* background-color:var(--app-primary); */

    /* height: 100vh; 
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black; */
  }
  
  .containerLogin {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* height: 100%; */
    justify-content: center; /* Center items vertically */
    padding: 20px;
    /* background-color: var(--app-bg-color); */
    /* border-radius: 8px; Optional: for rounded corners */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); Optional: for shadow effect */
  }

  /* :root {
    --global-white-color: white;
    --app-primary:#F67138;
    --app-text-color: white;
    --app-bg-color: black;
  } */
  
  .containerLogin1 {
    /* height: 100%; */
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    /* width: 100%; */
    padding: 20px;
    /* justify-content: center; */
    align-content: center;
    align-items: center;
    box-sizing: border-box; /* Include padding in width calculation */
    background-color: rgb(156, 24, 24);
  }
  .container1 {
    padding: 20px;
  }
  
  .scrollView {
    overflow-y: scroll;
  }
  
  .textColor12 {
    /* color: white; */
    color:  var(--app-text-color);;
  }
  
  .textCenter {
    text-align: center;
  }
  
  .bold {
    font-weight: bold;
  }

  .font-fam-serif{
    font-family: 'Arial', sans-serif;
  }
  
  .italic {
    font-style: italic;
  }
  
  .textSecondaryBold14 {
    font-weight: bold;
    /* color: #666; */
    color:var(--app-primary);
    /* background-color: var(--app-bg-color); */
    text-align: center;

  }
  .textBgSecondaryBold12 {
    font-weight: bold;
    /* color: #666; */
    font-size: 16px;
    color:var(--app-primary);
    background-color: var(--app-bg-secondary);
    text-align: center;

  }
  
  .marginTop20 {
    margin-top: 20px;
  }
  
  .row {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    flex-wrap: wrap;
  }
  
  .col {
    flex: 1;
    width: 100%;
    height: 100%;
    padding: 10px;
    margin: 10px;
  }

  .rowViewContests {
    width: 60%;
    flex-direction: row;
    display: flex; /* Ensure it's a flex container */
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px 0; /* Adjust padding as needed */
  }
  
  .rowView-scroll {
    display: flex;
    overflow-x: auto; /* Enable horizontal scrolling */
    padding: 10px;
    white-space: nowrap; /* Prevent line breaks */
    gap: 10px; /* Space between items */
  }

  .rowView {
    flex: 1;
    padding: 10px;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  
  .circularImageContainerMedium {
    width: 45px;
    height: 45px;
    overflow: hidden;
    border-radius: 50%;
  }

  .appLogoImageContainer {
    width: 85px;
    height: 85px;
    overflow: hidden;
    align-items: center;
    /* border-radius: 50%; */
  }

  .appNameImageContainer {
    width: 200px;
    height: 100px;
    overflow: hidden;
    align-items: center;
    /* border-radius: 50%; */
  }
  
  .circularImageMedium2 {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* background-color: var(--app-bg-color); */
  }
  
  .column {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    gap: 2px; /* Adjust the spacing between elements */

  }
  
  
  
  
  .textBg8Width {
    margin: 0; /* Remove default margin */
    padding: 5px 0; /* Adjust padding as needed */
    width: 100%;
    font-size: 12px;
    text-align: center;
    /* color: #444; */
    /* color: white; */
    color:  var(--app-text-color);;

  }
  
  .black {
    color: black;
  }
  
  .green {
    color: green;
  }
  
  .red {
    color: red;
  }

  .secondary {

    color: #F67138;
  }
  /* .inputWithPrefix2 {
    padding-left: 20px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center; 
    width: 100%;
    color: var(--app-primary); 
border: 2px solid var(--app-green); 
outline: none; 
background-color: var(--app-bg-color);
border-radius: 20px;
  } */

  .inputWithPrefix11 {
    /* position: relative; */
    display: flex;
    /* flex: 1;
    align-items: center;
    align-self: center;
    align-content: center;
    justify-content: center; */
    width: 40%;
    color: var(--app-primary); /* Change text color */
border: 2px solid var(--app-green); /* Outline color */
outline: none; /* Remove default outline */
background-color: var(--app-bg-color);
border-radius: 20px;
  }
  
  .prefix {
    position: absolute;
    left: 10px; /* Adjust the left position as needed */
    top: 50%;
    transform: translateY(-50%);
    padding: 0 8px;
    background-color: var(--app-text-color);
    border: 1px solid var(--app-text-color);
    border-right: none;
    border-radius: 4px 0 0 4px;
  }

  .inputTextOtp {
    /* width: calc(100% - 50px); Adjust for prefix width and padding */
    width: 120%;
    max-width: 400px;
    padding: 8px;
    margin: 5px 0;
    font-size: 15px;


    /* width: 80%; */
    box-sizing: border-box;
    outline: none; /* Remove default outline */
background-color: var(--app-bg-color);
box-sizing: border-box;
border: 1px solid var(--app-text-color);
color:var(--app-primary);
border-radius: 10px;
margin-bottom: 15px;
text-align: center;
margin-bottom: 20px;
  }
  .inputTextLogin {
    /* padding: 8px; */
    font-size: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 8px;
    padding-bottom: 8px;
    outline: none; /* Remove default outline */
background-color: var(--app-bg-color);
box-sizing: border-box;
border: 1px solid var(--app-bg-color);
color: var(--app-primary); /* Change text color */

    /* margin: 5px 0; */
    width: 100%;
    /* box-sizing: border-box; */
    
margin-right: 10px;
  }

  .inputTextLogin::placeholder {

    color: var(--app-gray); /* Adjust the color and transparency here */
  }

  .inputText:focus {
    border-color: var(--app-primary); 
  }
  .inputTextOtp:focus {
    border-color: var(--app-primary); 
  }
  
  .submitButton {
    display: flex;
    flex-direction: row;
    /* padding: 8px 56px; */
    padding: 12px 30px !important; /* Force the padding to apply */
    /* color: white; */
    font-size: 12px;
    color:  var(--app-text-color);;
    border: none;
    cursor: pointer;
    border-radius: 15px;
    border: 1px solid var(--app-text-color);

  }
  
  
  .submitButton:hover {
    border: 1px solid var(--app-primary);
    color:  var(--app-primary);;
    cursor: pointer;
    background-color:var(--app-bg-color);

    /* background-color: var(--app-green); */
  }

  .submitButtonTopBar {
    display: flex;
    flex-direction: row;
    padding: 5px 10px !important; /* Force the padding to apply */
    font-size: 12px;
    color:  var(--app-text-color);;
    border: none;
    cursor: pointer;
    border-radius: 15px;
    border: 1px solid var(--app-text-color);
    text-decoration: none;
  }
  
  
  .submitButtonTopBar:hover {
    border: 1px solid var(--app-primary);
    color:  var(--app-primary);;
    cursor: pointer;
    background-color:var(--app-bg-color);
  }

  .marketTopBar {
    display: flex;
    flex-direction: row;
    padding: 5px 10px !important; /* Force the padding to apply */
    font-size: 10px;
    color:  var(--app-text-color);;
    border: none;
    cursor: pointer;
    border-radius: 15px;
    border: 1px solid var(--app-text-color);
    text-decoration: none;
  }
  
  
  .marketTopBar:hover {
    border: 1px solid var(--app-primary);
    color:  var(--app-primary);;
    cursor: pointer;
    background-color:var(--app-bg-color);
  }


  .google-login-icon {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    /* padding: 5px; */
    align-self: center;
    /* margin-bottom: 5px; Space between the icon and the text */
  }
  
  @media (max-width: 768px) {
    .submitButtonTopBar {
      padding: 2px 5px !important; /* Force the padding to apply */
      font-size: 10px;
      border-radius: 15px;
      border: 1px solid var(--app-text-color);
    }
    .textBgSecondaryBold12 {
      font-size: 12px;
    }
    .container {
      max-width: 100%; /* Full width for mobile screens */
      padding: 10px;   /* Reduce padding for smaller screens */
    }
  
    .wrapper {
      padding: 10px;   /* Reduce padding for smaller screens */
    }

  }
  