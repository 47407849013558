/* src/BottomBar.css */
.topbar {
  background-color: var(--app-primary);
  /* color: #fff; */
  padding: 10px;
  flex: 1;
  display: flex;
}
.app-rowView {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 5px; Adjust padding as needed */
  margin: 0; /* Ensure no margin */
}
.app-rowView {
  height: 50px;
  align-items: center;
  align-content: center;
  flex-direction: row;
  /* background-color: var(--app-bg-color); */
  background-color: var(--app-bg-secondary);

}
.botbar {
  /* background-color: var(--app-bg-color); */
  background-color: var(--app-bg-secondary);

  color: var(--app-bg-color);
  position: fixed;
  bottom: 0;
  padding-top: 10px;
  height: 50px; /* Adjust this value as needed */
  /* box-shadow: 0 -2px 5px rgb(255, 255, 255); Optional shadow for visual separation */
  z-index: 1; /* Ensure it stays on top */
  width: 100%; /* Ensure it spans the full width */
  /* overflow-x: auto;  */
  /* Make it horizontally scrollable */

}

.botbar-market {
  /* background-color: var(--app-bg-color); */
  background-color: var(--app-bg-secondary);

  flex-direction: row;
  color: var(--app-bg-color);
  position: fixed;
  /* bottom: 0; */
  top: 40;
  padding-top: 10px;
  height: 50px; /* Adjust this value as needed */
  /* box-shadow: 0 -2px 5px rgb(255, 255, 255); Optional shadow for visual separation */
  z-index: 1; /* Ensure it stays on top */
  width: 100%; /* Ensure it spans the full width */
  /* overflow-x: auto;  */
  /* Make it horizontally scrollable */

}

.topbar nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-around; /* Distribute items evenly */
}

.topbar nav ul li {
  flex: 1;
  display: inline;
  margin: 30px;
  text-align: center;
  /* border: 2px solid #28a745; Outline color */
  border-radius: 15px;
  padding: 8px;
}

.topbar nav ul li a {
  color: var(--app-primary);
  text-decoration: none;
}

.topbar nav ul li a:hover {
  text-decoration: underline;
}

.logo {
  width: 20px; /* Adjust the width as needed */
  height: 20px; /* Adjust the height as needed */
  margin-right: 8px; /* Space between image and text */
  vertical-align: middle; /* Align the image with the text */
}

.botbar nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  /* flex: 1; */
  display: flex;
  justify-content: space-around; /* Distribute items evenly */
  align-items: center; /* Center items vertically */
}

.botbar nav ul li {
  flex: 1;
  /* flex: 0 0 auto;  */
  /* Prevent flex items from growing or shrinking */
  display: flex;
  justify-content: center; /* Center the link horizontally */
  align-items: center; /* Center the link vertically */
  flex-direction: column; /* Stack icon and title vertically */

  height: 15px; 
  /* width: 90px; */
  /* margin: 5px; */
  padding: 10px;
  font-size: 8px;
  text-align: center;
  color: var(--app-text-color);
}

.botbar nav ul li a {
  /* flex: 1; */
  color: var(--app-text-color);
  text-decoration: none;
  flex-direction: column; /* Stack icon and title vertically */
  fill: var(--app-text-color);
  /* filter: brightness(0) invert(1);  */
  /* fill:aliceblue; */
  /* width: 10vb; */
  /* display: block; Ensure the link covers the entire list item */
  height: 100%; /* Make the link fill the height of the list item */
  padding: 10px; /* Match the padding of the list item */
  border-radius: 10px; /* Match the border-radius of the list item */
  /* border: 1px solid var(--app-text-color); Apply default border to the link */
  display: flex;
  justify-content: center;
  align-items: center;
}


.botbar nav ul li a.active {
  fill: var(--app-primary);
  color: var(--app-primary);
  /* filter: brightness(0) invert(1);  */
  /* filter: sepia(100%) saturate(10000%) hue-rotate(10deg) brightness(0.9); */

  /* border: 1px solid var(--app-primary); Apply primary color border to the active link */
}


.botbar nav ul li a:hover {
  cursor: pointer;
}

.nav-icon {
  /* fill: var(--app-text-color);  */
  /* fill: white; This will fill the SVG with the default text color */
  /* border: 1px solid red; To check visibility */

  /* fill: azure; */
  filter: brightness(0) invert(1); 
  /* color: #fff; */
  width: 20px;
  height: 20px;
  margin-bottom: 5px; /* Space between the icon and the text */
  /* filter: sepia(100%) saturate(10000%) hue-rotate(10deg) brightness(0.9); */
  /* transition: fill 0.3s ease; */

}

.botbar nav ul li a.active .nav-icon {
  fill: var(--app-primary); /* This will fill the SVG with the primary color */
}

.bot-text-12 {
  font-size: 12px;
}

@media (max-width: 768px) {

  .botbar {
    height: 40px; /* Adjust this value as needed */
    font-size: 10px; /* Adjust font size if needed */
    padding: 5px; /* Adjust padding if needed */
  }

  .bot-text-12 {
    font-size: 8px;
  }
}
@media (max-width: 480px) {

  .botbar {
    height: 30px; /* Adjust this value as needed */
    font-size: 8px; /* Adjust font size if needed */
    padding: 5px; /* Adjust padding if needed */
  }

  .bot-text-12 {
    font-size: 6px;
  }
}
@supports (-webkit-overflow-scrolling: touch) {
  .botbar {
    -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
  }

}

