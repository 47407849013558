.App {
  text-align: center;
}
body, html, #root, .App {
  width: 100%;
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  background-color: var(--app-bg-secondary);
  overflow: hidden; /* Prevent scrolling */

}

.content {
  flex: 1;
  overflow-y: auto;
}

.CustomBottomBar {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: var(--app-text-color); /* Adjust as needed */
  box-shadow: 0 -2px 5px var(--app-text-color); /* Optional shadow */
  z-index: 1000;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

:root {
  --global-white-color: white;
  --app-primary:#F67138;
  --app-text-color: #333333;
  --app-bg-color: #ffffff;
  --app-bg-secondary: #f2f2f2;

  --app-green: green;
  --app-red: red;
  --app-gray:  rgba(127, 127, 127, 0.5);
}
/* color: var(--global-white-color); Use the global CSS variable */

.app-container {
  height: 100%;
  flex: 1;
  width: 100%;
   /* display: flex; */
  justify-content: center;
  align-items: center;
  background-color: var(--app-bg-color);

}

.app-container2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Ensure the container takes up the full height of the viewport */
  /* background-color: green; */
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bottom {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  align-content: baseline;
}

.rowViewContests {
  width: 70%;
  height: 10px;
  flex-direction: row;
  display: flex; /* Ensure it's a flex container */
  flex-wrap: wrap;
  /* background-color: #007bff; */
  /* justify-content: space-between; */
  /* padding: 5px 0; */
}
.app-rowView {
  /* flex: 1; */
  height: 50px;
  align-items: center;
  align-content: center;
  /* width: 100%; */
  flex-direction: row;
  /* display: flex; */
  /* flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 0; 
  margin: 0;  */
  /* background-color: rgb(216, 43, 43); */

}
.rowView100per {
  display: flex;
  justify-content: center; /* Center the items horizontally */
  flex-wrap: wrap;
  width: 100%;
  padding: 10px 0; /* Adjust padding as needed */
  margin: 0; /* Ensure no margin */
}
.rowView100per-flex{
  align-items: center;
  align-content: center;
  
  flex: 1;
  width: 60%;
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 0; /* Adjust padding as needed */
  margin: 0; /* Ensure no margin */
  /* background-color: rgb(216, 43, 43); */

}

.rowView50per {
  /* flex: 1; */
  width: 40%;
  flex-direction: row;
  display: flex;
  /* flex-wrap: wrap; */
  align-items: center;
  justify-content: space-between;
  padding: 5px; /* Adjust padding as needed */
  margin: 0; /* Ensure no margin */
  /* background-color: rgb(216, 43, 43); */

}

.appColumn {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 2px; /* Adjust the spacing between elements */

}
.appColumn100 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers content horizontally */
  /* background-color: #F67138; */
}
.appColumn1002 {
  display: flex;
  flex: 1;
  width: 100%;
  /* flex-direction: column; */
  align-content: center;
  justify-content: center;
  align-items: center;
  /* gap: 2px; Adjust the spacing between elements */
/* background-color: #218838; */
}
.appColumn100-flex {
  display: flex;
  flex: 1;
  width: 100%;
  /* flex-direction: column; */
  align-content: center;
  justify-content: center;
  align-self: center;
  align-items: center;
 
}

.rowView {
  flex: 1;
  padding: 10px;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.app-cryptoItem {
  /* Style for each item in userList */
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  /* border-bottom: 1px solid #ccc; Example border */
  border: 1px solid var(--app-text-color);
  cursor: pointer;
}
.transaction-list {
  /* width: 100vh; 
  max-width: 200vh;  */
  
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column; /* Stack the items vertically */
  align-items: center; /* Align items to the start (left) */
  justify-content: center;
}

/* Each individual transaction */
.transaction-list2 {
  width: 100%; /* Make sure each transaction takes full width */
  padding: 10px 0; /* Adjust padding as needed */
  display: flex;
  flex-direction: column; /* Stack items vertically inside each transaction */
  align-items: center; /* Align items to the start (left) */
  justify-content: center;
  box-sizing: border-box; /* Ensure padding and borders are included in width */
}
.app-crypto-list {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.app-crypto-image {
  width: 30px;
  height: 30px;
}
.profile-picture{
  align-items: center;
  align-content: center;
  justify-content: center;
  /* background-color: #0056b3; */
}
.app-profile-image {
  width: 40px;
  height: 40px;
  border: 2px solid var(--app-green); /* Outline color */
  /* padding: 5px; */
  border-radius: 35px;
  /* justify-content: center; */
}

.cryptoItem {
  display: flex;
  justify-content: space-around;
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 10px;
  /* border: 1px solid white; */
  cursor: pointer;
}

.cryptoItem:hover {
  border: 1px solid var(--app-text-color); /* Outline color */
  /* background-color: var(--app-green); */
}
.cryptoItem.selected {
  border: 4px solid var(--app-green); /* Outline color */
  /* background-color: var(--app-green); */
}

.appBgContainer {
  padding: 0;
  margin: 0; /* Ensure no margin */
  background-color:var(--app-bg-color);
}

.inputWithPrefix {
  /* padding-left: 20px; */
  padding: 0px 10px;
  display: flex;
  align-items: center;
  align-content: center;
  /* flex-direction: row; */
  justify-content: center; /* Horizontally center content */
  width: 100%;
  max-width: 400px; /* Adjust the maximum width as needed */
  margin-bottom: 10px; /* Optional: Adjust margin */
  color: var(--app-primary); /* Change text color */
border: 1px solid var(--app-green); /* Outline color */
outline: none; /* Remove default outline */
background-color: var(--app-bg-color);
border-radius: 10px;
}

.inputWithPrefix::placeholder {

  color: var(--app-gray); /* Adjust the color and transparency here */
}


.inputWithPrefix-contest-small {
  /* padding-left: 20px; */
  display: flex;
  align-items: center;
  align-content: center;
  align-self: center;
  justify-content: center;
  padding: 15px;
  height: 50px;
  /* flex-direction: row; */
  /* height: ; */
  justify-content: center; /* Horizontally center content */
  width: 100%;
  max-width: 100vh; /* Adjust the maximum width as needed */
  margin-bottom: 10px; /* Optional: Adjust margin */
  color: var(--app-primary); /* Change text color */
/* border: 2px solid var(--app-green);  */
outline: none; /* Remove default outline */
background-color: var(--app-bg-color);
border-radius: 10px;
box-shadow: 0px 2px 3.84px rgba(0, 0, 0, 0.35); /* Similar to shadowOpacity and shadowRadius */

}

.inputWithPrefix-toptype {
  /* padding-left: 20px; */
  display: flex;
  align-items: center;
  align-content: center;
  align-self: center;
  justify-content: center;
  padding: 10px;
  /* flex-direction: row; */
  /* height: ; */
  justify-content: center; /* Horizontally center content */
  width: 100%;
  max-width: 100vh; /* Adjust the maximum width as needed */
  margin-bottom: 10px; /* Optional: Adjust margin */

}

.inputWithPrefix-contest {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  align-self: center; /* Center the card horizontally within its parent */
  padding: 10px; /* Adjust padding */
  width: 90%; /* Set width to 90% of the parent container */
  max-width: 70vh; /* Adjust the maximum width as needed */
  height: 80px; /* Set a fixed height */
  max-height: 100px;
  border-radius: 15px; /* Rounded corners */
  background-color: var(--app-bg-color); 
  box-shadow: 2px 4px 1.84px rgba(0, 0, 0, 0.35); /* Similar to shadowOpacity and shadowRadius */
  margin: 10px 0; /* Vertical margin */
  color: var(--app-primary); /* Text color */
  /* border: 2px solid var(--app-green);  */
}

.inputWithPrefix-entry {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  align-self: center; /* Center the card horizontally within its parent */
  padding: 10px; /* Adjust padding */
  width: 90%; /* Set width to 90% of the parent container */
  max-width: 90vh; /* Adjust the maximum width as needed */
  height: 200px; /* Set a fixed height */
  border-radius: 15px; /* Rounded corners */
  background-color: var(--app-bg-color); 
  box-shadow: 2px 4px 1.84px rgba(0, 0, 0, 0.35); /* Similar to shadowOpacity and shadowRadius */
  margin: 10px 0; /* Vertical margin */
  color: var(--app-primary); /* Text color */
  /* border: 2px solid var(--app-green);  */
}


/* Child items */
.inputWithPrefix-mycontest {
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px; /* Set a fixed width or adjust as needed */
  max-width: 200px; /* Max width to handle larger screens */
  margin-bottom: 10px;
  color: var(--app-primary);
  /* border: 2px solid var(--app-green); */
  background-color: var(--app-bg-color);
  border-radius: 20px;
  padding: 10px; /* Adjust padding for content spacing */
  flex-shrink: 0; /* Prevent shrinking when content overflows */
  box-shadow: 0px 2px 3.84px rgba(0, 0, 0, 0.35); /* Similar to shadowOpacity and shadowRadius */

}


.inputWithPrefix2 {
  padding: 10px;
  display: flex;
  text-align: center;
  align-items: center;
  align-content: center;
  justify-content: center;
  /* flex-direction: row; */
  /* justify-content: center; Horizontally center content */
  width: 40%;
  font-size: 14px;
  /* height: 10px; */
  max-width: 200px; 
  /* Adjust the maximum width as needed */
  margin-bottom: 10px; /* Optional: Adjust margin */
  color: var(--app-primary); /* Change text color */
border: 1px solid var(--app-green); /* Outline color */
outline: none; /* Remove default outline */
background-color: var(--app-bg-color);
border-radius: 10px;
}

.inputWithPrefix3 {
  padding: 10px;
  display: flex;
  text-align: center;
  align-items: center;
  align-content: center;
  justify-content: center;
  /* flex-direction: row; */
  /* justify-content: center; Horizontally center content */
  width: 60%;
  font-size: 14px;
  /* height: 10px; */
  max-width: 200px; 
  /* Adjust the maximum width as needed */
  margin-bottom: 10px; /* Optional: Adjust margin */
  color: var(--app-primary); /* Change text color */
border: 1px solid var(--app-green); /* Outline color */
outline: none; /* Remove default outline */
background-color: var(--app-bg-color);
border-radius: 10px;
}

.inputWithPrefix:hover {
  /* background-color: #218838; */
  cursor: pointer;
}
.inputWithPrefix2:hover {
  border: 2px solid var(--app-primary); /* Outline color */
  /* background-color: #218838; */
  cursor: pointer;
}

.safe-area {
  /* background-color: #61dafb; */
  /* width: 100%; */
  /* max-width: 600px; */
  margin: 0 auto;
  padding: 20px;
  flex: 1; /* Allow the safe-area to grow and take up available space */
}
.button-fixed-top {
  position: fixed;
  bottom: 100px;
  width: 30%;
  align-items: center;
  align-self: center;
  align-content: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
 }
.button-fixed {
 position: fixed;
 bottom: 50px;
 width: 30%;
 align-items: center;
 align-self: center;
 align-content: center;
 justify-content: center;
}
.button-fixed-50 {
  position: fixed;
  bottom: 50px;
  width: 50%;
  align-items: center;
  align-self: center;
  align-content: center;
  justify-content: center;
 }
.button-fixed-100 {
  position: fixed;
  bottom: 50px;
  width: 100%;
  align-items: center;
  align-self: center;
  align-content: center;
  justify-content: center;
 }
.profile-card {
  flex: 1;
  background-color: var(--app-bg-color);
  border-radius: 10px;
  box-shadow: 0 4px 8px var(--app-bg-color);
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Space out children, pushing last one to bottom */
  /* height: 100%; */
}
.app-container3 {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Make sure the container takes the full height of the viewport */
}

.safe-area2 {
  width: 100%;
  max-width: 600px;
  /* height: 100%; */
  margin: 0 auto;
  padding: 20px;
  flex: 1; /* Allow the safe-area to grow and take up available space */
  display: flex;
  flex-direction: column;
}



.center2 {
  display: flex;
  flex: 1;
  justify-content: center;
  position: absolute;
  bottom: 60px;
  width: 50%;
  /* align-items: center; */
  align-self: center;
  flex-grow: 1; /* Allow the safe-area to grow and take up available space */
  /* align-items: end; */
  margin-top: 20px; /* Add some space at the top */
}


.appTextBg10 {
  margin: 0; /* Remove default margin */
padding: 5px 0; /* Adjust padding as needed */
  width: 100%;
  font-size: 14px;
  padding: 0px;
  text-align: center;
  color: var(--app-text-color);
}
.appTextColor10-0 {
  margin: 0; /* Remove default margin */
padding: 5px 0; /* Adjust padding as needed */
  /* width: 100%; */
  font-size: 10px;
  padding: 0px;
  text-align: center;
  color: var(--app-text-color);
}

.appTextColor10 {
  margin: 0; /* Remove default margin */
padding: 5px 0; /* Adjust padding as needed */
  width: 100%;
  font-size: 10px;
  padding: 0px;
  text-align: center;
  color: var(--app-text-color);
}

.appTextColor8 {
  margin: 3px; /* Remove default margin */
/* padding: 5px 0; Adjust padding as needed */
  /* width: 100%; */
  font-size: 8px;
  padding: 0px;
  text-align: center;
  color: var(--app-text-color);
}

.appTextColor8end {
  margin: 0; /* Remove default margin */
padding: 5px 0; /* Adjust padding as needed */
  width: 100%;
  font-size: 8px;
  padding: 0px;
  text-align: end;
  color: var(--app-text-color);
}
.appBgColor10 {
  margin: 0; /* Remove default margin */
padding: 5px 0; /* Adjust padding as needed */
  /* width: 100%; */
  font-size: 10px;
  text-align: center;
  color: var(--app-bg-color);
  /* background-color: #61dafb; */
}
.appBgColor10-pointer {
  margin: 0; /* Remove default margin */
padding: 5px; /* Adjust padding as needed */
  /* width: 100%; */
  font-size: 12px;
  text-align: center;
  color: var(--app-bg-color);
  cursor: pointer;
  /* background-color: #61dafb; */
}
.appTextColor12 {
  margin: 0; /* Remove default margin */
padding: 5px 0; /* Adjust padding as needed */
  width: 100%;
  font-size: 12px;
  padding: 0px;
  text-align: center;
  color: var(--app-text-color);
  /* background-color: #61dafb; */
}
.appTextColor14 {
  margin: 0; /* Remove default margin */
padding: 5px 0; /* Adjust padding as needed */
  width: 100%;
  font-size: 14px;
  padding: 0px;
  text-align: center;
  color: var(--app-text-color);
  /* background-color: #61dafb; */
}
.appTextColor16 {
  margin: 0; /* Remove default margin */
padding: 5px 0; /* Adjust padding as needed */
  width: 100%;
  font-size: 16px;
  padding: 0px;
  text-align: center;
  color: var(--app-text-color);
  /* background-color: #61dafb; */
}
.appTextColor18 {
  margin: 0; /* Remove default margin */
padding: 5px 0; /* Adjust padding as needed */
  width: 100%;
  font-size: 18px;
  padding: 0px;
  text-align: center;
  color: var(--app-text-color);
  /* background-color: #61dafb; */
}
.appTextColor12Start {
  margin: 0; /* Remove default margin */
/* padding: 5px 0; Adjust padding as needed */
  width: 100%;
  font-size: 12px;
  padding: 0px;
  text-align: start;
  color: var(--app-text-color);
}
.appTextColor12End-1 {
  margin: 0; /* Remove default margin */
/* padding: 5px 0; Adjust padding as needed */
  width: 100%;
  font-size: 12px;
  padding: 0px;
  text-align: end;
  color: var(--app-text-color);
}
.appTextColor12End {
  margin: 0; /* Remove default margin */
/* padding: 5px 0; Adjust padding as needed */
  /* width: 100%; */
  font-size: 12px;
  padding: 0px;
  text-align: center;
  color: var(--app-text-color);
  width: 100px;
}
.appTextColor14-0{
  color: var(--app-text-color);
  text-align: center;
  font-size: 14px;
}

.appTextColor14 {
  margin: 0; /* Remove default margin */
padding: 5px 0; /* Adjust padding as needed */
  width: 100%;
  font-size: 14px;
  padding: 0px;
  text-align: center;
  color: var(--app-text-color);
  font-weight: bold;
}

.appPrimaryColor12 {
  margin: 0; /* Remove default margin */
padding: 5px 0; /* Adjust padding as needed */
  width: 100%;
  font-size: 12px;
  padding: 0px;
  text-align: center;
  color: var(--app-primary);
  /* background-color: #61dafb; */
}

.appPadding10{
  padding: 10px; /* Adjust padding as needed */
}

.appPadding20{
  padding: 20px; /* Adjust padding as needed */
}
.appPadding30{
  padding: 30px; /* Adjust padding as needed */
}


.app-width-40{
  width: 40%;
}

.top-margin-10{
  margin-top: 10px; /* Adjust padding as needed */
}
.top-margin-20{
  margin-top: 20px; /* Adjust padding as needed */
}
.app-margin-10{
  margin: 10px; /* Adjust padding as needed */
}
.app-margin-20{
  margin: 20px; /* Adjust padding as needed */
}
.app-margin-30{
  margin: 30px; /* Adjust padding as needed */
}

.scrollView {
  max-height: 80vh;
  overflow-y: scroll;
}

.instructions {
  text-align: center;
  margin-bottom: 20px;
  font-size: 14px;
  color: var(--app-text-color);
}

.sortOptions {
  align-content: center;
  align-self: center;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.crypo-select-sort-Item-select {
  /* Style for each item in userList */
  /* width: 100%; */
  font-size: 10px;
  padding: 8px;
  color: var(--app-primary);
  border-radius: 10px;
  /* border-bottom: 1px solid #ccc; Example border */
  border: 1px solid var(--app-primary);
  cursor: pointer;
  margin: 5px;
}
.crypo-select-sort-Item-unselect {
  /* Style for each item in userList */
  /* width: 100%; */
  font-size: 10px;
  padding: 8px;
  color: var(--app-text-color);
  border-radius: 10px;
  /* border-bottom: 1px solid #ccc; Example border */
  border: 1px solid var(--app-text-color);
  cursor: pointer;
  margin: 5px;
}

.greenText {
  color: green;
}

.redText {
  color: red;
}


/* Chat.css */
.chat-container1 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
}
.chat-container22 {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
  width: 80%; 
  /* max-width: 600px; */
  margin: 0 auto;

  /* box-sizing: border-box; */
}

.chat-container {
  display: flex;
  flex-direction: column;
  /* height: 100vh; */

  width: 100%;
  /* margin-bottom: 50px; */
  max-width: 600px;
  margin: 0 auto;
  box-sizing: border-box;
}

.messages-container {
  flex: 1;
  padding: 20px;
  /* margin-bottom: 150px; */
  overflow-y:visible;
  display: flex;
  flex-direction: column;
}

.input-container {
  /* flex:1; */
  display: flex;
  padding: 10px;
  /* border-top: 1px solid #ccc; */
  /* border: 1px solid var(--app-text-color); */
  background-color: var(--app-bg-color); /* Optional background color */
  flex-shrink: 0; /* Prevent shrinking */
  position: sticky;
  bottom: 0;
}

.chat-input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 10px;
  background-color: var(--app-bg-color);
  color: var(--app-text-color);
}

.chat-send-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.chat-send-button:hover {
  background-color: #0056b3;
}

.smallButton {
  padding: 8px 16px;
  /* background-color: #F67138; */
  color: var(--app-text-color);
  /* color:  var(--app-text-color);; */
  border: 1px solid var(--app-text-color);
  cursor: pointer;
  border-radius: 20px;
}

.smallButton:hover {
  color: var(--app-primary);
  border: 1px solid var(--app-primary);
  /* background-color: #45a049; */
}
.messages-container1 {
  flex: 1;
  padding: 20px;
  /* overflow-y: auto; */
  display: flex;
  flex-direction: column;
}

.message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  max-width: 100%;
  /* flex: 1; */
}


.my-message {
  /* background-color: #007bff; */
  color: var(--app-text-color);
  align-self: flex-end;
  /* align-content: flex-end;
  align-items: flex-end;
  justify-content: flex-end;
  justify-self: flex-end; */
  /* flex: 1; */
  /* width: '30%'; */
  max-width: 80%;
}

.other-message {
  /* background-color: #f1f1f1; */
  color: var(--app-text-color);
  align-self: flex-start;
  /* width: '30%'; */
  max-width: 80%;

}

.message-sender {
  font-weight: bold;
}

.message-content {
  margin-top: 5px;
}

.message-timestamp {
  margin-top: 5px;
  font-size: 0.3em;
  color: var(--app-text-color);
}
.message-delete {
  margin-top: 5px;
  font-size: 0.7em;
  color: var(--app-red);
  cursor: pointer;
  border: 1px solid var(--app-red);
  width: 50px;
  border-radius: 5px;
}

.input-container1 {
  /* overflow-y: none; */
  bottom: 10px;
  width: 90%;
  /* align-content: center; */
  /* align-items: center; */
  align-self: center;
  background-color: var(--app-bg-color);
  /* position: absolute; */
  display: flex;
  padding: 20px;
  border-top: 1px solid #ccc;
}

/* =----------------------      leader board -------------------- */
.leaderboard-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  /* border-bottom: 1px solid #ddd; */
  flex-direction: row;
  /* flex: 1; */
}
.leaderboard-item:hover {
  cursor: pointer;
  /* border-color: var(--app-text-color); */
  /* border-width: 1px; */
  border-radius: 15px;
  border: 1px solid var(--app-text-color);
    /* border-bottom: 1px solid #ddd; */
}
.appTextColor12flex {
  margin: 0; /* Remove default margin */
padding: 5px 0; /* Adjust padding as needed */
  width: 100%;
  font-size: 12px;
  padding: 0px;
  text-align: center;
  color: var(--app-text-color);
  flex: 1;
}
.appTextColor14flex {
  margin: 0; /* Remove default margin */
padding: 5px 0; /* Adjust padding as needed */
  width: 100%;
  font-size: 14px;
  padding: 0px;
  text-align: center;
  color: var(--app-text-color);
  flex: 1;
}
.appTextColor14start {
  margin-left: 50px;
padding: 5px 0; /* Adjust padding as needed */
  width: 100%;
  font-size: 14px;
  padding: 0px;
  text-align: start;
  color: var(--app-text-color);
  /* flex: 1; */
}
.privacy-align{
  text-align: start;
}
.margin-10{
  /* margin: 0px; */
}
.appPrimaryColor10start2 {
  margin: 15px;
  display: flex; /* Use flexbox for layout */
  align-items: center; /* Center items vertically */
  color: var(--app-primary);
  font-size: 16px;

}
.appPrimaryColor10end {
  margin: 15px;
  display: flex; /* Use flexbox for layout */
  align-items: end; /* Center items vertically */
  color: var(--app-primary);
  font-size: 16px;

}
.appPrimaryColor10start {
  margin-top: 30px; /* Remove default margin */
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
padding: 5px 0; /* Adjust padding as needed */
  width: 100%;
  font-size: 16px;
  padding: 0px;
  text-align: start;
  color: var(--app-primary);
  text-decoration: none; /* Remove underline by default */
  /* flex: 1; */
}
.appPrimaryColor14start {
  margin-top: 20px; /* Remove default margin */
  margin-bottom: 10px;
  margin-left: 30px;
  margin-right: 20px;
padding: 5px 0; /* Adjust padding as needed */
  width: 100%;
  font-size: 14px;
  padding: 0px;
  text-align: start;
  color: var(--app-primary);
  /* flex: 1; */
}
.appPrimaryColor10flex {
  margin: 0; /* Remove default margin */
padding: 5px 0; /* Adjust padding as needed */
  width: 100%;
  font-size: 10px;
  padding: 0px;
  text-align: center;
  color: var(--app-primary);
  flex: 1;
}
.appGreen12 {
  margin: 0; /* Remove default margin */
padding: 5px 0; /* Adjust padding as needed */
  width: 100%;
  font-size: 12px;
  padding: 0px;
  text-align: center;
  font-weight: bold;
  color: var(--app-green);
  /* flex: 1; */
}
.leaderboard-item-details {
  display: flex;
  align-items: center;
}

.leaderboard-item-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.leaderboard-item-points {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.leaderboard-item-profit {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.my-teams {
  background-color: var(--app-bg-color);
  padding: 10px;
}
.my-teams-border-bot {
  border-bottom: 1px solid var(--app-text-color);
  width: 100%;
}

/* Ensure the main content doesn't get hidden behind the bottom bar */
.app-content {
  overflow: hidden; /* This hides both the scrollbar and the scrollable content */

  /* For scrollable content with hidden scrollbar */
  overflow-y: scroll; /* Enables scrolling vertically without showing scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer and Edge */

  padding-bottom: 70px; /* Adjust this value to match the height of CustomBottomBar */
}
.message-placeholder {
  width: 200px;
  height: 300px;
  background-color: var(--app-gray);
}

.message-image {
  max-width: 100%;
  max-height: 400px;

  /* width: 100px;
  height: 200px; */
  object-fit: contain;
  border-radius: 8px;

  /* background-color: #007bff; */
  /* max-width: 100%;
  max-height: 400px; Larger height for certain images */
  /* object-fit: cover;  */
}
.message-image.hidden {
  display: none;
}

.message-image.visible {
  display: block;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
 @media (max-width: 480px) {
  .appBgColor10-pointer {
  padding: 5px; /* Adjust padding as needed */
    font-size: 6px;
  }
  .inputWithPrefix-contest-small {
    /* width: 60%; */
    max-width: 70vh;
    height: 30px;
  }
  .inputWithPrefix2 {
    padding: 5px;
  max-width: 100px;
  font-size: 12px;

  }
  .appTextColor12 {
  padding: 3px 0; /* Adjust padding as needed */
    width: 100%;
    font-size: 8px;
  }
  .appTextColor14{
    font-size: 12px;
  }
  .appTextColor14-0{
    font-size: 12px;
  }
  .appTextColor12Start{
    font-size: 8px;
  }
  .appTextColor12End{
    font-size: 8px;
  }
  .app-crypto-image {
    width: 20px;
    height: 20px;
  }

.inputWithPrefix-contest {
  width: 100%;
  max-width: 320px;
}

.inputWithPrefix-mycontest {
  width: 600%;
  max-width: 200px;
}

.app-profile-image {
  width: 30px;
  height: 30px;
}

  }