.container-gainer {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .loading-gainer {
    text-align: center;
    font-size: 24px;
  }
  
  .sort-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .sort-buttons button {
    margin: 0 10px;
    padding: 10px 20px;
    background-color: var(--app-bg-color);
    border: 1px solid #ccc;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .sort-buttons button.active {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
  }
  
  .crypto-table {
    display: flex;
    flex-direction: column;
    /* border-top: 1px solid #ccc; */
  }
  
  .crypto-header, .crypto-row {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr 2fr 1fr;
    padding: 10px 0;
  }
  
  .crypto-header-gainer {
    font-weight: bold;
    background-color: var(--app-bg-secondary);
  }
  
  .crypto-row {
    border-bottom: 1px solid var(--app-bg-secondary);
  }
  
  .crypto-row span {
    padding: 5px;
  }
  
  .crypto-image-gainer {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .positive {
    color: var(--app-green);
  }
  
  .negative {
    color: var(--app-red);
  }
  