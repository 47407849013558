
/* Container for the entire progress bar */
.progress-container {
    width: 320px; /* Width of the progress bar */
    height: 3px; /* Height of the progress bar */
    background-color: var(--app-gray); /* Background color for unfilled portion */
    border-radius: 8px; /* Rounded corners */
    position: relative;
    overflow: hidden; /* Prevents overflow of inner div */
  }
  
  /* Filled portion of the progress bar */
  .progress-bar {
    height: 100%;
    background-color: var(--app-primary); /* Primary color (filled part) */
    transition: width 0.4s ease; /* Smooth transition when the bar fills */
    border-radius: 8px 0 0 8px; /* Rounded corners on the left */
  }
  
  .progress-bar1 {
    width: 320px;
    height: 3px;
    background-color: #f3f3f3; /* Adjust unfilled color */
    border: none; /* Remove default border */
    appearance: none;
  }
  
  .progress-bar::-webkit-progress-bar {
    background-color: var(--app-gray); /* Unfilled color */
  }
  
  .progress-bar::-webkit-progress-value {
    background-color: var(--app-primary); /* Filled color */
  }
  
  .progress-bar::-moz-progress-bar {
    background-color: var(--app-primary); /* Filled color for Firefox */
  }
  