.container-trending {
    padding: 20px;
    background-color: var(--app-bg-secondary);
  }
  
  .refresh-section {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .refresh-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: var(--app-bg-secondary);
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .sort-section {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .sort-button {
    margin: 0 10px;
    padding: 10px 15px;
    background-color: var(--app-bg-secondary);
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .sort-button.active {
    background-color: var(--app-bg-color);
    color: var(--app-primary);
  }

  .submitButtonTopBar.active {
    background-color: var(--app-bg-color);
    color: var(--app-primary);
    border: 1px solid var(--app-primary);
  }
  
  
  .crypto-list-trending {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .crypto-card-trending {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: var(--app-bg-secondary);
    border-radius: 10px;
    /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */
    align-items: center;
  }
  
  .crypto-rank {
    font-size: 12px;
    font-weight: bold;
  }
  
  .crypto-details {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .crypto-image-trending {
    width: 25px;
    height: 25px;
    border-radius: 20px;
  }
  
  .crypto-name {
    font-size: 12px;
    /* font-weight: bold; */
  }
  
  .crypto-price {
    font-size: 12px;
    color: gray;
  }
  
  .crypto-change {
    text-align: right;
  }
  
  .crypto-change-24h {
    font-size: 12px;
  }
  
  .crypto-change-24h.positive {
    color: var(--app-green);
  }
  
  .crypto-change-24h.negative {
    color: var(--app-red);
  }
  
  .loading-indicator {
    text-align: center;
    font-size: 12px;
  }
  